import React from 'react'


const Footer = () => {
  return (
    <>
        <div style={{marginBottom:"0rem",height:"5dvh",backgroundColor:"#3C5B6F"}}>

        </div>

        
    </>
  )
}

export default Footer